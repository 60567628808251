import BedUrin from "../assets/error_symbols/bed_urin.png";
import BleedingWound from "../assets/error_symbols/bleeding_wound.png";
import BloodPipe from "../assets/error_symbols/blood_pipe.png";
import CartFull from "../assets/error_symbols/cart_full.png";
import Chair from "../assets/error_symbols/chair.png";
import MissingSupply from "../assets/error_symbols/missing_supply.png";
import MoveableBed from "../assets/error_symbols/moveable_bed.png";
import Needle from "../assets/error_symbols/needle.png";
import NoseVentilator from "../assets/error_symbols/nose_ventilator.png";
import OpenWound from "../assets/error_symbols/open_wound.png";
import PillsWrongName from "../assets/error_symbols/pills_wrong_name.png";
import SpilledGlass from "../assets/error_symbols/spilled_glass.png";
import TrashFullBath from "../assets/error_symbols/trash_full_bath.png";
import TrashFullMain from "../assets/error_symbols/trash_full_main.png";
import UrinFull from "../assets/error_symbols/urin_full.png";
import UrineBottleFloor from "../assets/error_symbols/urine_bottle_floor.png";
import WayBlocker from "../assets/error_symbols/way_blocker.png";
import WrongPowerSocket from "../assets/error_symbols/wrong_power_socket.png";

//[]=================[ ADD MORE ERRORS HERE ]=================[]
//description, image, error ID
export const errorContent = [
    ["Urin im Bett", BedUrin, 0],
    ["Blutende Wunde", BleedingWound, 1],
    ["Blut im Infusionsschlauch", BloodPipe, 2],
    ["Überfüllter Schubschrank", CartFull, 3],
    ["Fehlerhafter Toilettenstuhl", Chair, 4],
    ["Fehlende Arbeitsmittel", MissingSupply, 5],
    ["Ungesichertes Bett", MoveableBed, 6],
    ["Herumliegende Spritzen", Needle, 7],
    ["Fehlerhafte Luftzufuhr", NoseVentilator, 8],
    ["Offene Wunde", OpenWound, 9],
    ["Falscher Medikamentenname", PillsWrongName, 10],
    ["Verschüttetes Glas", SpilledGlass, 11],
    ["Badmülleimer voll", TrashFullBath, 12],
    ["Mülleimer voll", TrashFullMain, 13],
    ["Voller Urinbehälter", UrinFull, 14],
    ["Urinbehälter am Boden", UrineBottleFloor, 15],
    ["Gehhilfe im Weg", WayBlocker, 16],
    ["Verwendung vom Notstrom", WrongPowerSocket, 17],
]